var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "signature-dialog" },
      [
        _c("VueSignaturePad", {
          ref: "signaturePad",
          staticStyle: {
            border: "dashed 2px #aaaaaa",
            "box-sizing": "content-box",
          },
          attrs: {
            width: _vm.signatureWidth,
            height: _vm.signatureHeight,
            options: _vm.options,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }