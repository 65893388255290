<template>
  <div>
    <div class="signature-dialog">
      <!-- <div class="title">手写签名</div> -->
      <VueSignaturePad ref="signaturePad" :width="signatureWidth" :height="signatureHeight" :options="options" style="border:dashed 2px #aaaaaa;box-sizing:content-box;" />
      <!-- <div class="signature-tool">
        <div style="display:inline-block">
          <div class="signature-tool-item" v-if="!disabled" @click="clearSignatureMobile">清空</div>
          <div class="signature-tool-item" v-if="!disabled" @click="undoSignatureMobile">撤销</div>
        </div>
        <div style="display:inline-block">
          <el-button size="mini" type="primary" @click="saveSign" v-if="!disabled">确定</el-button>
          <el-button size="mini" @click="closeSign">关闭</el-button>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import Cookies from 'js-cookie'
import { aliUploader } from '@/utils/oss'
import { generateCode } from '@/api/system/form'
// import { getUserInfo } from '@/api/system/user'

// import { getSessionStorageObj, setSessionStorageObj } from '@/utils/db'
export default {
  data() {
    return {

      fieldModel: "",
      disabled: false,
      fillId: "",
      formId: "",
      status: "",


      signatureWidth: 'calc(100vw - 104px)',
      signatureHeight: 'calc(100vh - 24px)',
      rules: [],
      options: {
        penColor: "#000",
        // backgroundColor: 'rgba(255,255,255,1)',
      },
      aliOSSUpload: null,
      fileName: "",
      source: "",    //wechat_audit 微信小程序审核 h5_fill 填报时扫码签字  h5_audit PC审核时扫码签字
      qrcode: '',
    }
  },
 created() {
    this.formId = this.$route.params.formId
    this.fillId = this.$route.params.fillId
    this.agencyId = this.$route.params.agencyId
    this.status = this.$route.params.status
    // this.signType = this.$route.params.signType

    const token = this.$route.params.token
    Cookies.set('User-Token', token)


    // let userInfo = getSessionStorageObj('users')
    // if (!userInfo) {
    //   let res = await getUserInfo()
    //   setSessionStorageObj('users', res.data)
    //   userInfo=res.data
    // }

    this.fileName = this.$route.query.fileName
    this.fieldModel = this.fileName
    this.source = this.$route.query.source




  },
  async mounted() {

    this.showSign()
  },
  // watch: {
  //   value: function (val) {
  //     // this.fieldModel = val
  //   }
  // },
  methods: {
    showSign() {
      let $this = this;

      let signaturePad = $this.$refs.signaturePad
      let canvasImageData;

      //先显示出来
      this.$nextTick(async () => {

        this.aliOSSUpload = aliUploader(false, { //OK
          formId: this.formId || 'formId',
          agencyId: this.agencyId,
        })

        console.log("加载文件",this.fieldModel+".txt")

        if (this.fieldModel) {
          canvasImageData = (await axios.get($this.fieldModel + ".txt")).data;
        }

         this.rotateBase64Img(canvasImageData, 90, rotateData => {

        //判断是新表单、查看、修改 三种模式的哪一种
        //禁用的一定是查看模式
        if ($this.disabled) {
          //显示保存的数据
          signaturePad.lockSignaturePad();
          //如果是移动版，需要旋转90度再显示
          signaturePad.fromDataURL(rotateData)
          // }
        } else {
          //否则可能是修改或者新增
          //判断fieldModel是否为空，为空就是新增或者修改的原纪录无信息，判断是否使用历史信息
          if (!$this.fieldModel) {
            let image = localStorage.getItem("v-form-signature");
            if (image) {
              signaturePad.fromDataURL(image)
            }
          } else {
            signaturePad.fromDataURL(rotateData)
          }
        }



         })

      });
    },
    async saveSign() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      new Promise((resolve) => {

        this.rotateBase64Img(data, 270, rotateData => {


          this.cropSignatureCanvas(rotateData, 30).then(async (imageData) => {

            // if (!this.fileName) {
            // let newFileName = (new Date()).valueOf() + imageData.substring(imageData.length - 10) + '.png';
            //   this.fileName = newFileName
            // }
            this.fileName = (new Date()).valueOf() + "audit-sign.png"
            try {
              let file = this.dataUrlToFile(imageData,this.fileName)
              let originFileData = this.dataUrlToBase64File(rotateData,this.fileName + ".txt")

              //裁剪前的Base64
              this.aliOSSUpload.config={
                  uploadPath:`auditSign/${this.agencyId}/${this.formId}/${this.fillId}/${this.status}`,
                  fixedName:true
              }
              const uploadImage =()=>{
                return new Promise(uploadResolve=>{
                  this.aliOSSUpload.config.uploadSuccessHandler=(file,fileList)=>{
                        this.fieldModel = file.url;

                    console.log("url",file.url)
                        uploadResolve();
                  }
                  this.aliOSSUpload.elFileUpload({file:file})
                })
              }
              const uploadTxt =()=>{
                return new Promise(uploadResolve=>{
                   this.aliOSSUpload.config.uploadSuccessHandler=(file,fileList)=>{
                    if (!isEmpty) {
                      if (file.url.endsWith(".txt")) {
                        //保存到localStorage
                        localStorage.setItem("v-form-signature", data);
                      }
                    }
                    console.log("url",file.url)
                    uploadResolve();
                  }
                  this.aliOSSUpload.elFileUpload({file:originFileData})

                })
              }

              Promise.resolve().then(uploadImage).then(uploadTxt).then(() => {
                  //通知微信签好了返回
                  if (this.source == "wechat_audit") {
                    console.log("post message", this.fieldModel)
                    wx.miniProgram.postMessage({
                      data: {
                        redirect: `signConfirm`,
                        fileName: this.fieldModel
                      }
                    })
                  }
                  resolve();
              }).catch(()=>{console.log(e)})




            } catch (e) {
              this.$message.warning("保存签字出错");
              console.error("保存签字出错")
              console.error(e)
            }
          });


        })

      }).then(() => {
        wx.miniProgram.navigateBack()
      })
    },
    closeSign() {
      wx.miniProgram.navigateBack()
    },
    undoSignatureMobile() {
      this.$refs.signaturePad.undoSignature()
    },
    clearSignatureMobile() {
      this.$refs.signaturePad.clearSignature()

      localStorage.removeItem("v-form-signature");
    },
    // base64图片旋转方法
    rotateBase64Img(src, edg, callback) {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw '旋转角度必须是90的倍数!';
      }
      (edg < 0) && (edg = (edg % 360) + 360)
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = {
        sx: 0,
        sy: 0,
        ex: 0,
        ey: 0
      }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous"

      image.onload = () => {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }


        ctx.translate(size, size);
        ctx.rotate(edg * Math.PI / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        callback(canvas.toDataURL())
      };

      image.src = src;

    },
    //裁剪签名空白区，并返回图片
    async cropSignatureCanvas(url, padding = 0) {
      return new Promise((resolve, reject) => {
        // create canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // create image
        const image = new Image();
        image.onload = draw;
        image.src = url;
        image.crossOrigin = "Anonymous";

        function draw() {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const { data, width, height } = imageData;

          // 裁剪需要的起点和终点,初始值为画布左上和右下点互换设置成极限值。
          let startX = width,
            startY = height,
            endX = 0,
            endY = 0;

          /*
          col为列，row为行，两层循环构造每一个网格，
          便利所有网格的像素，如果有色彩则设置裁剪的起点和终点
          */
          for (let col = 0; col < width; col++) {
            for (let row = 0; row < height; row++) {
              // 网格索引
              const pxStartIndex = (row * width + col) * 4;

              // 网格的实际像素RGBA
              const pxData = {
                r: data[pxStartIndex],
                g: data[pxStartIndex + 1],
                b: data[pxStartIndex + 2],
                a: data[pxStartIndex + 3]
              };

              // 存在色彩：不透明
              const colorExist = pxData.a !== 0;

              /*
              如果当前像素点有色彩
              startX坐标取当前col和startX的最小值
              endX坐标取当前col和endX的最大值
              startY坐标取当前row和startY的最小值
              endY坐标取当前row和endY的最大值
              */
              if (colorExist) {
                startX = Math.min(col, startX);
                endX = Math.max(col, startX);
                startY = Math.min(row, startY);
                endY = Math.max(row, endY);
              }
            }
          }

          // 右下坐标需要扩展1px,才能完整的截取到图像
          endX += 1;
          endY += 1;

          // 加上padding
          startX -= padding;
          startY -= padding;
          endX += padding;
          endY += padding;

          // 根据计算的起点终点进行裁剪
          const cropCanvas = document.createElement("canvas");
          const cropCtx = cropCanvas.getContext("2d");
          cropCanvas.width = endX - startX;
          cropCanvas.height = endY - startY;
          cropCtx.drawImage(
            image,
            startX,
            startY,
            cropCanvas.width,
            cropCanvas.height,
            0,
            0,
            cropCanvas.width,
            cropCanvas.height
          );

          //设置白色背景
          const bgImage = cropCtx.getImageData(0, 0, cropCanvas.width, cropCanvas.height);
          for (let i = 0; i < imageData.data.length; i += 4) {
            // 当该像素是透明的，则设置成白色
            if (bgImage.data[i + 3] === 0) {
              bgImage.data[i] = 255;
              bgImage.data[i + 1] = 255;
              bgImage.data[i + 2] = 255;
              bgImage.data[i + 3] = 255;
            }
          }
          cropCtx.putImageData(bgImage, 0, 0);

          // rosolve裁剪后的图像字符串
          resolve(cropCanvas.toDataURL());
        }

      })
    },
    dataUrlToFile(dataurl, fileName) {

      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let blob = new Blob([u8arr], { type: mime });

      blob.lastModifiedDate = new Date();
      blob.name = fileName;
      return blob;
    },
    dataUrlToBase64File(dataurl, fileName) {
      let mime = 'text/plain'
      let blob = new Blob([dataurl], { type: mime });

      blob.lastModifiedDate = new Date();
      blob.name = fileName;
      return blob;
    }
  }
}
</script>
<style lang="scss" scoped>
.signature-box {
  width: 100%;
  height: 70px;
  border: solid 1px #e5e5e5;
  margin-top: 35px;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &.empty {
    text-align: center;
    line-height: 70px;
    color: #a5a5a5;
    &::before {
      content: '请点击点击签名';
    }
  }
}

::v-deep .signature {
  width: 600px;
  position: relative;
  border: dashed 2px #aaaaaa;
  border-radius: 4px;
  box-sizing: content-box;
  background-color: #f5f5f5;
  user-select: none;


  &-tool {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #f5f5f5;
    border: solid 1px #c5c5c5;
    display: inline-flex;
    padding: 0px 10px;
    box-sizing: border-box;
    justify-content: flex-end;
    &-item {
      display: inline-block;
      padding: 2px 8px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #888888;
      }
    }
  }
}

.signature-dialog {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 10px;
  padding-right: 50px;
  padding-left: 50px;
  box-sizing: border-box;

  background-color: #f5f5f5;
  z-index: 99999;

  .title {
    position: absolute;
    right: -90px;
    height: 25px;
    text-align: left;
    line-height: 25px;
    display: inline-block;
    overflow: hidden;
    width: 100px;
    text-align: left;

    transform: rotate(90deg);
    transform-origin: 0px 0px;
  }

  .signature-tool {
    position: absolute;
    left: 10px;
    top: -30px;
    width: 100vh;
    height: 30px;
    line-height: 30px;

    border: none 0px;
    background-color: #f5f5f5;
    display: inline-flex;
    box-sizing: border-box;
    justify-content: space-between;
    transform: rotate(90deg);
    transform-origin: 0 bottom;
  }

  &.window-style {
    top: auto;
    padding-right: 10px;
    padding-left: 10px;

    .title {
      position: initial;
      transform: initial;
    }

    .signature-tool {
      position: initial;
      transform: initial;
      left: 0px;
      right: 0px;
      top: 0px;

      padding-right: 0px;
      padding-left: 0px;

      width: 100%;
    }
  }
}
</style>
